const wordsList = [
    "Accident",
    "Achat",
    "Acné",
    "Action",
    "Adolescent",
    "Afrique",
    "Aiguille",
    "Allumer",
    "Alpes",
    "Alphabet",
    "Altitude",
    "Amérique",
    "Ami",
    "Amour",
    "Ampoule",
    "Anniversaire",
    "Appétit",
    "Araignée",
    "Arbre",
    "Arc",
    "Arc-en-ciel",
    "Argent",
    "Arme",
    "Armée",
    "Ascenseur",
    "Asie",
    "Assis",
    "Astronaute",
    "Atchoum",
    "Athlète",
    "Atlantide",
    "Aube",
    "Australie",
    "Avec",
    "Aventure",
    "Avion",
    "Avocat",
    "Bac",
    "Baguette",
    "Bain",
    "Baiser",
    "Balai",
    "Balle",
    "Ballon",
    "Bambou",
    "Banane",
    "Bannir",
    "Barbe",
    "Barrière",
    "Bas",
    "Basket",
    "Bateau",
    "Bâton",
    "Batterie",
    "Bébé",
    "Beethoven",
    "Bête",
    "Biberon",
    "Bière",
    "Blanc",
    "Blé",
    "Bleu",
    "Bob",
    "Boisson",
    "Boîte",
    "Bombe",
    "Bonbon",
    "Bonnet",
    "Bord",
    "Bordeaux",
    "Botte",
    "Boue",
    "Bougie",
    "Boule",
    "Bouteille",
    "Bouton",
    "Branche",
    "Bras",
    "Bravo",
    "Bretagne",
    "Brise",
    "Brosse",
    "Bruit",
    "Brume",
    "Brun",
    "Bûche",
    "Bulle",
    "Bureau",
    "But",
    "Cabane",
    "Cabine",
    "Cacher",
    "Cadeau",
    "Cafard",
    "Café",
    "Caisse",
    "Calculer",
    "Calme",
    "Caméra",
    "Camion",
    "Camping",
    "Canada",
    "Canard",
    "Canette",
    "Canine",
    "Cap",
    "Capitalisme",
    "Car",
    "Carotte",
    "Carré",
    "Carte",
    "Carton",
    "Casque",
    "Casser",
    "Cassette",
    "Cauchemar",
    "Cause",
    "Ceinture",
    "Cellule",
    "Cercle",
    "Chaîne",
    "Chair",
    "Chaise",
    "Champ",
    "Champion",
    "Chant",
    "Chapeau",
    "Charbon",
    "Charge",
    "Chasse",
    "Chat",
    "Château",
    "Chaud",
    "Chaussure",
    "Chauve",
    "Chef",
    "Chemise",
    "Chêne",
    "Cher",
    "Cheval",
    "Chevalier",
    "Cheveu",
    "Chien",
    "Chiffre",
    "Chine",
    "Chocolat",
    "Chômage",
    "Ciel",
    "Cil",
    "Cinéma",
    "Cire",
    "Cirque",
    "Citron",
    "Clé",
    "Clou",
    "Clown",
    "Coach",
    "Coccinelle",
    "Code",
    "Cœur",
    "Col",
    "Colle",
    "Colline",
    "Colonne",
    "Cône",
    "Confort",
    "Continu",
    "Contre",
    "Conversation",
    "Copain",
    "Coq",
    "Coquillage",
    "Corbeau",
    "Corde",
    "Corps",
    "Côte",
    "Coude",
    "Couloir",
    "Coup",
    "Cour",
    "Courant",
    "Courrier",
    "Cours",
    "Course",
    "Court",
    "Couteau",
    "Couvert",
    "Couverture",
    "Cowboy",
    "Crac",
    "Crayon",
    "Crème",
    "Critique",
    "Crochet",
    "Croix",
    "Croûte",
    "Cuillère",
    "Cuir",
    "Cuisine",
    "Culotte",
    "Cycle",
    "Dard",
    "Dé",
    "Debout",
    "Défaut",
    "Dehors",
    "Démocratie",
    "Dent",
    "Dentiste",
    "Dessin",
    "Devoir",
    "Diamant",
    "Dictionnaire",
    "Dieu",
    "Dinosaure",
    "Discours",
    "Disque",
    "Dix",
    "Docteur",
    "Doigt",
    "Domino",
    "Dormir",
    "Droit",
    "Eau",
    "Échec",
    "Échelle",
    "Éclair",
    "École",
    "Écran",
    "Écraser",
    "Écrit",
    "Église",
    "Égout",
    "Électricité",
    "Éléphant",
    "Élève",
    "Elfe",
    "Empreinte",
    "Enceinte",
    "Épice",
    "Épine",
    "Erreur",
    "Espace",
    "Espion",
    "Essence",
    "État",
    "Été",
    "Étoile",
    "Étranger",
    "Éventail",
    "Évolution",
    "Explosion",
    "Extension",
    "Face",
    "Fan",
    "Farce",
    "Fatigue",
    "Fauteuil",
    "Femme",
    "Fenêtre",
    "Fer",
    "Fête",
    "Feu",
    "Feuille",
    "Fidèle",
    "Fil",
    "Fille",
    "Flamme",
    "Flèche",
    "Fleur",
    "Fleuve",
    "Fond",
    "Football",
    "Forêt",
    "Forger",
    "Foudre",
    "Fouet",
    "Four",
    "Fourmi",
    "Froid",
    "Fromage",
    "Front",
    "Fruit",
    "Fuir",
    "Futur",
    "Garçon",
    "Gâteau",
    "Gauche",
    "Gaz",
    "Gazon",
    "Gel",
    "Genou",
    "Glace",
    "Gomme",
    "Gorge",
    "Goutte",
    "Grand",
    "Grèce",
    "Grenouille",
    "Grippe",
    "Gris",
    "Gros",
    "Groupe",
    "Guitare",
    "Hasard",
    "Haut",
    "Hélicoptère",
    "Herbe",
    "Heureux",
    "Histoire",
    "Hiver",
    "Hôtel",
    "Hugo",
    "Huile",
    "Humide",
    "Humour",
    "Indice",
    "Internet",
    "Inviter",
    "Italie",
    "Jacques",
    "Jambe",
    "Jambon",
    "Jardin",
    "Jaune",
    "Jean",
    "Jeanne",
    "Jet",
    "Jeu",
    "Jogging",
    "Jour",
    "Journal",
    "Jupiter",
    "Kilo",
    "Kiwi",
    "Laine",
    "Lait",
    "Langue",
    "Lapin",
    "Latin",
    "Laver",
    "Lecteur",
    "Léger",
    "Lent",
    "Lettre",
    "Lien",
    "Ligne",
    "Linge",
    "Lion",
    "Lit",
    "Livre",
    "Loi",
    "Long",
    "Louis",
    "Loup",
    "Lumière",
    "Lundi",
    "Lune",
    "Lunette",
    "Machine",
    "Macho",
    "main",
    "Maison",
    "Maîtresse",
    "Mal",
    "Maladie",
    "Maman",
    "Mammouth",
    "Manger",
    "Marais",
    "Marc",
    "Marche",
    "Mariage",
    "Marie",
    "Mariée",
    "Marque",
    "Marseille",
    "Masse",
    "Mer",
    "Messe",
    "Mètre",
    "Métro",
    "Miaou",
    "Micro",
    "Mieux",
    "Mille",
    "Mine",
    "Miroir",
    "Moderne",
    "Moitié",
    "Monde",
    "Monstre",
    "Montagne",
    "Montre",
    "Mort",
    "Moteur",
    "Moto",
    "Mou",
    "Mouche",
    "Moulin",
    "Moustache",
    "Mouton",
    "Mur",
    "Muscle",
    "Musique",
    "Mystère",
    "Nage",
    "Nature",
    "Neige",
    "Neutre",
    "NewYork",
    "Nez",
    "Nid",
    "Ninja",
    "Niveau",
    "Noël",
    "Nœud",
    "Noir",
    "Nous",
    "Nuage",
    "Nuit",
    "Numéro",
    "Œil",
    "Œuf",
    "Oiseau",
    "Olympique",
    "Ombre",
    "Ongle",
    "Or",
    "Oral",
    "Orange",
    "Ordinateur",
    "Ordre",
    "Ordure",
    "Oreille",
    "Organe",
    "Orgueil",
    "Ours",
    "Outil",
    "Ouvert",
    "Ovale",
    "Pain",
    "Palais",
    "Panneau",
    "Pantalon",
    "Pantin",
    "Papa",
    "Papier",
    "Papillon",
    "Paradis",
    "Parc",
    "Paris",
    "Parole",
    "Partie",
    "Passe",
    "Pâte",
    "Patin",
    "Patte",
    "Paul",
    "Payer",
    "Pêche",
    "Peinture",
    "Pendule",
    "Penser",
    "Personne",
    "Petit",
    "Peur",
    "Philosophe",
    "Photo",
    "Phrase",
    "Piano",
    "Pièce",
    "Pied",
    "Pierre",
    "Pile",
    "Pilote",
    "Pince",
    "Pioche",
    "Pion",
    "Pirate",
    "Pire",
    "Piscine",
    "Place",
    "Plafond",
    "Plage",
    "Plaie",
    "Plan",
    "Planche",
    "Planète",
    "Plante",
    "Plastique",
    "Plat",
    "Plat",
    "Plomb",
    "Plonger",
    "Pluie",
    "Poche",
    "Poète",
    "Poids",
    "Poing",
    "Point",
    "Poivre",
    "Police",
    "Politique",
    "Pollen",
    "Polo",
    "Pomme",
    "Pompe",
    "Pont",
    "Population",
    "Port",
    "Porte",
    "Portefeuille",
    "Positif",
    "Poste",
    "Poubelle",
    "Poule",
    "Poupée",
    "Pousser",
    "Poussière",
    "Pouvoir",
    "Préhistoire",
    "Premier",
    "Présent",
    "Presse",
    "Prier",
    "Princesse",
    "Prise",
    "Privé",
    "Professeur",
    "Psychologie",
    "Public",
    "Pull",
    "Punk",
    "Puzzle",
    "Pyjama",
    "Quatre",
    "Quinze",
    "Race",
    "Radio",
    "Raisin",
    "Rap",
    "Rayé",
    "Rayon",
    "Réfléchir",
    "Reine",
    "Repas",
    "Reptile",
    "Requin",
    "Rêve",
    "Riche",
    "Rideau",
    "Rien",
    "Rire",
    "Robinet",
    "Roche",
    "Roi",
    "Rond",
    "Rose",
    "Roue",
    "Rouge",
    "Rouille",
    "Roux",
    "Russie",
    "Sable",
    "Sabre",
    "Sac",
    "Sain",
    "Saison",
    "Sale",
    "Salle",
    "Salut",
    "Samu",
    "Sandwich",
    "Sang",
    "Sapin",
    "Satellite",
    "Saumon",
    "Saut",
    "Savoir",
    "Schtroumpf",
    "Science",
    "Scout",
    "Sec",
    "Seine",
    "Sel",
    "Sept",
    "Serpent",
    "Serrer",
    "Sexe",
    "Shampooing",
    "Siècle",
    "Siège",
    "Sieste",
    "Silhouette",
    "Sirène",
    "Ski",
    "Soleil",
    "Sommeil",
    "Son",
    "Sonner",
    "Sorcière",
    "Sourd",
    "Souris",
    "Sport",
    "Star",
    "Station",
    "Stylo",
    "Sur",
    "Surface",
    "Sushi",
    "Swing",
    "Tableau",
    "Tache",
    "Taille",
    "Tante",
    "Tapis",
    "Tard",
    "Taxi",
    "Téléphone",
    "Télévision",
    "Temple",
    "Temps",
    "Tennis",
    "Tête",
    "Thé",
    "Tigre",
    "Tintin",
    "Tissu",
    "Titre",
    "Titre",
    "Toast",
    "Toilette",
    "Tokyo",
    "Tombe",
    "Ton",
    "Top",
    "Touche",
    "Toujours",
    "Tour",
    "Tournoi",
    "Tout",
    "Trace",
    "Train",
    "Traîner",
    "Transport",
    "Travail",
    "Trésor",
    "Triangle",
    "Triste",
    "Trône",
    "Troupeau",
    "Tsar",
    "Tube",
    "Tuer",
    "Tuer",
    "Tupperware",
    "Tuyau",
    "Twitter",
    "Type",
    "Université",
    "Vache",
    "Vache",
    "Vague",
    "Vaisselle",
    "Valeur",
    "Ver",
    "Verdict",
    "Verre",
    "Vers",
    "Vert",
    "Veste",
    "Viande",
    "Vide",
    "Vie",
    "Vieux",
    "Ville",
    "Vin",
    "Vingt",
    "Violon",
    "Vipère",
    "Vision",
    "Vite",
    "Vive",
    "Vœu",
    "Voile",
    "Voisin",
    "Voiture",
    "Vol",
    "Volume",
    "Vote",
    "Vouloir",
    "Voyage",
    "Zen",
    "Zéro",
    "Zodiaque",
    "Zone",
    "Zoo"
]

export default wordsList;